import React from 'react'
import Page from '../../_common/_components/Page/Page'
import Title from '../../_common/_components/Title/Title'
import Breadcrumb from '../../_common/_components/Breadcrumb/Breadcrumb'
import TitleBar from '../../_common/_components/TitleBar/TitleBar'
import Button, { Buttons } from '../../_common/_components/Button/Button'
import TextArea from '../../_common/_components/TextArea/TextArea'
import useFetch from '../../_common/_Hooks/useFetch'
import useForm from '../../_common/_Hooks/useForm'

const SMS = () => {
    const { formPlug, validate, inputs, setInputs } = useForm()

    const { req: getDetails } = useFetch({
        endpoint: 'template/sms',
        method: 'get',
        selfCall: true,
        success: (res) => {
            
            setInputs({
                english_text: res.data.english_text,
                arabic_text: res.data.arabic_text,
               
            })
        }
    })
    // *********************************************************************************************************************

    const { req: save } = useFetch({
        endpoint: 'template/sms/update',
        method: 'post',
        type: 'raw',
        toast: true
    })
// *********************************************************************************************************************
    async function submitHandler() {
        if (!await validate()) return
        save({
            english_text: inputs?.english_text,
            arabic_text:inputs?.arabic_text
        
        }, () => {
            getDetails()
        })
    }
 // *********************************************************************************************************************
    async function onCancelHandler (){
        setInputs({})
        getDetails()
    }

  return (
    <Page>
    <Page.Header>
        <Title title='SMS' />
        <Breadcrumb />
    </Page.Header>
    <Page.Body css={1}>
        <TitleBar css={1} title='SMS' toggleButton >
            
                <TextArea
                    css={2}
                    label='English'
                    field={'english_text'}
                    value={inputs}
                    mb={20}
                    inputSocket={formPlug}
                    required
                />
               
               <TextArea
                    css={2}
                    label='Arabic'
                    field={'arabic_text'}
                    value={inputs}
                     required
                    inputSocket={formPlug}
                />
               
           
            <Buttons mt={50} mb={30}>
                <Button right content="Cancel" css={1} onClick={onCancelHandler}  />
                <Button right content="Submit" css={1.1}  onClick={submitHandler} />
            </Buttons>
        </TitleBar>
    </Page.Body>
</Page>
  )
}

export default SMS