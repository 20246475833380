import React, { useState } from "react";
import Title from "../_common/_components/Title/Title";
import Breadcrumb from "../_common/_components/Breadcrumb/Breadcrumb";
import Page from "../_common/_components/Page/Page";
import Table from "../_common/_components/Table/Table";
import DatePick from "../_common/_components/DatePick/DatePick";
import Select from "../_common/_components/Select/Select";
import StatusBox from "../_common/_components/StatusBox/StatusBox";
import Image from "../_common/_components/Image/Image";
import FilterBox from "../_common/_components/FilterBox/FilterBox";
import { IMG_BASE_URL } from "../config";
import DateViewer from "../_common/_components/DateViewer/DateViewer";
import useFilter from "../_common/_Hooks/useFilter";
import useTranslate from "../_common/_Hooks/useTranslate";
import ReportCounts from "../components/ReportCounts/ReportCounts";
import useFetch from "../_common/_Hooks/useFetch";
import { Capitalize } from "../helpers";

export default function Reports() {
  const { _lang } = useTranslate();

  const requestTypeData = [
    { label: "Manual", value: "manual" },
    { label: "Call Back", value: "call_back" },
    { label: "Voice Mail", value: "voice_mail" },
    { label: "Mobile App", value: "mobile_app" },
    { label: "Website", value: "website" },
    { label: "Email", value: "email" },
  ];

  const genderData = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const annonymousData = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];

  const advancedSearchOptions = [
    { label: "Anonymous", value: "anonymous" },
    { label: "Gender", value: "gender" },
  ];

  // *********************************************************************************************************************
  const { req: getReports, res: report_res } = useFetch({
    endpoint: "report/getRequestReport",
    method: "post",
    type: "formdata",
    toast: false,
  });
  // *********************************************************************************************************************
  const { res: department_res } = useFetch({
    endpoint: "department/getDepartmentInRequest",
    method: "post",
    type: "formdata",
    selfCall: true,
  });
  // *********************************************************************************************************************
  const { res: country_res } = useFetch({
    endpoint: "common/getFullCountries",
    method: "get",
    type: "raw",
    selfCall: true,
  });
  // *********************************************************************************************************************
  const { res: customers_res } = useFetch({
    endpoint: "request/getCustomersInRequest",
    method: "post",
    type: "formdata",
    selfCall: true,
  });
  // *********************************************************************************************************************
  const { res: agents_res } = useFetch({
    endpoint: "request/getAgentsInRequest",
    method: "post",
    type: "formdata",
    selfCall: true,
  });
  // *********************************************************************************************************************
  const { res: status_res } = useFetch({
    endpoint: "common/getAllStatus",
    method: "get",
    type: "formdata",
    selfCall: true,
  });
  // *********************************************************************************************************************
  const { req: exportReports } = useFetch({
    endpoint: "report/getRequestReportExport",
    method: "post",
    type: "formdata",
    download: true,
  });
  // *********************************************************************************************************************
  const { res: incidentTypes_res } = useFetch({
    endpoint: "incidentType/getIncidentTypeInRequest",
    method: "post",
    type: "raw",
    selfCall: true,
    privateLoader: true,
  });
  // *********************************************************************************************************************
  let reportData = report_res?.data;
  let countData = report_res?.countData;
  let pageDetails = report_res?.page;
  let departments = department_res?.data;
  let countries = country_res?.data;
  let customers = customers_res?.data;
  let agents = agents_res?.data;
  let statuses_for_filter = [
    "resolved",
    "forward",
    "processing",
    "closed",
    "to_do",
    "assigned",
    "waiting_approval",
    "need_info",
    "escalated",
    "rejected",
    "approved",
    "reopen",
  ];
  let allStatus = status_res?.data.filter((s) =>
    statuses_for_filter.includes(s.handle_key)
  );
  let incidentTypes = incidentTypes_res?.data;
  // *********************************************************************************************************************

  let defaultTitleFilter = [
    // { label: "Ticket Number", value: "ticket_no" },
    { label: "Created Date", value: "created_date" },
    { label: "Handled By", value: "handled_by" },
    { label: "Reporter Name", value: "customer_name" },
    { label: "Phone Number", value: "customer_contact" },
    { label: "Email", value: "customer_email" },
    { label: "Request Type", value: "request_type" },
    { label: "Incident Type", value: "incident_type" },
    { label: "Assigned Department", value: "department" },
    { label: "Status", value: "status" },
  ];
  const [advancedFilter, setAdvancedFilter] = useState(defaultTitleFilter);
  const [advancedSearch, setAdvancedSearch] = useState([]);

  const { filterData, filterHandler, F_navigate } = useFilter({
    initialFilter: { page: 0, perPage: 10 },
    // Fetch: getReports,
    Fetch: (filter) => {
      getReports(filterDataHandler(filter));
    },
    Export: exportReports,
  });

  function filterDataHandler(filter) {
    let filterData = { ...filter };
    delete filterData.advance_filter;
    if (
      advancedSearch?.some(
        (ele) => ele?.value == "anonymous" && filter?.anonymous
      )
    ) {
      filterData = {
        ...filterData,
        anonymous: filter?.anonymous == "yes" ? true : false,
      };
    }
    return {
      ...filterData,
    };
  }

  const data = {
    Closed: countData?.closedCount,
    Escalated: countData?.escalatedCount,
    Forwarded: countData?.forwardCount,
    Assigned: countData?.assignedCount,
    "Need Info": countData?.needInfoCount,
    Processing: countData?.processingCount,
    Rejected: countData?.rejectedCount,
    Resolved: countData?.resolvedCount,
    "To Do": countData?.toDoCount,
    Total: countData?.totalCount,
    "Waiting For Approval": countData?.waitingForApprovalCount,
    Reopened: countData?.reopenedCount,
  };

  const titles = [
    { title: "Ticket Number", key: "ticket_no", sort: true },
    { title: "Created Date", key: "created_date", sort: true },
    { title: "Handled By", key: "handled_by", sort: true },
    { title: "Reporter Name", key: "customer_name", sort: true },
    {
      title: "Phone Number",
      key: "customer_contact",
      dir: "ltr",
      d_align: "right",
      sort: true,
    },
    { title: "Email", key: "customer_email", sort: true, d_align: "right" },
    { title: "Request Type", key: "request_type", sort: true },
    { title: "Incident Type", key: "incident_type", sort: true },
    { title: "Assigned Department", key: "department", sort: true },

    { title: "Anonymous", key: "is_anonymous", sort: true },
    { title: "Reported Location", key: "precise_location", sort: true },
    { title: "IP Address", key: "ip_addr", sort: true },
    { title: "Device", key: "device", sort: true },
    { title: "Device ID", key: "device_id", sort: true },
    { title: "Communication Language", key: "reported_lang", sort: true },
    { title: "Customer Latitude", key: "latitude", sort: true },
    { title: "Customer Longitude", key: "longitude", sort: true },
    { title: "Nationality", key: "customer_nationality", sort: true },
    { title: "Gender", key: "customer_gender", sort: true },

    { title: "Status", key: "status", sort: true },
  ];

  let AdvancedFilterOptions = [
    // { label: "Ticket Number", value: "ticket_no" },
    { label: "Created Date", value: "created_date" },
    { label: "Handled By", value: "handled_by" },
    { label: "Reporter Name", value: "customer_name" },
    { label: "Phone Number", value: "customer_contact" },
    { label: "Email", value: "customer_email" },
    { label: "Request Type", value: "request_type" },
    { label: "Incident Type", value: "incident_type" },
    { label: "Assigned Department", value: "department" },

    { label: "Anonymous", value: "is_anonymous" },
    { label: "Reported Location", value: "precise_location" },
    { label: "IP Address", value: "ip_addr" },
    { label: "Device", value: "device" },
    { label: "Device ID", value: "device_id" },
    { label: "Communication Language", value: "reported_lang" },
    { label: "Customer Latitude", value: "latitude" },
    { label: "Customer Longitude", value: "longitude" },
    { label: "Nationality", value: "customer_nationality" },
    { label: "Gender", value: "customer_gender" },

    { label: "Status", value: "status" },
  ];

  function advancedFilterChangeHandler(e) {
    setAdvancedFilter(s => [...s, e]);
  }

  const activeTitles = () => {
    let titlesToShow = [...titles];
    if (advancedFilter.length) {
      titlesToShow = titlesToShow.filter((item) => {
        if (item.key === "ticket_no") {
          return true;
        }
        if (advancedFilter.some((obj) => obj.value === item.key)) {
          return true;
        } else {
          return false;
        }
      });
    } else {
      titlesToShow = titlesToShow.filter((item) => {
        if (item.key === "ticket_no") {
          return true;
        }
        return false;
      });
    }
    return titlesToShow;
  };

  return (
    <Page className="incident_reports">
      <Page.Header>
        <Title title="Reports" />
        <Breadcrumb />
      </Page.Header>

      <Page.Body css={1}>
        <FilterBox
          _css={{
            Select: 1,
            DatePick: 1,
            Input: 1,
            Search_Btn: 1.1,
            Clear_Btn: 1.2,
            Export_Btn: 3,
          }}
          _placeholder={{ Select: "Select", Input: "Type in input" }}
          showCloseBtns
          filterHandler={filterHandler}
          filterData={filterData}
        >
          <Select
            label="Advance Filter"
            field="advance_filter"
            content={{ op: advancedSearchOptions, lb: "label", vl: "value" }}
            Multi2
            onChange={(e) => {
              if (e?.length) {
                setAdvancedSearch(e);
              } else {
                setAdvancedSearch([]);
              }
            }}
            value={advancedSearch}
          />
          <DatePick
            label="Start Date"
            field="start_date"
            maxDate={(e) => e.end_date}
          />
          <DatePick
            label="End Date"
            field="end_date"
            minDate={(e) => e.start_date}
          />
          <Select
            label="Customer Name"
            field="customer"
            content={{
              op: customers?.filter((a) => a?.customer_name_en),
              lb: "customer_name_en",
              vl: "customer_name_en",
            }}
          />
          <Select
            label="Department"
            field="department"
            content={{
              op: departments,
              lb: "department_name" + _lang,
              vl: "_id",
            }}
          />
          <Select
            label="Country"
            field="country"
            content={{ op: countries, lb: "name", vl: "_id" }}
          />
          <Select
            label="Incident Type"
            field="incidentType"
            content={{
              op: incidentTypes,
              lb: "incident_type" + _lang,
              vl: "_id",
            }}
          />
          <Select
            label="Request Type"
            field="request_type"
            content={{ op: requestTypeData, lb: "label", vl: "value" }}
          />
          <Select
            label="Handled By"
            field="agent"
            content={{ op: agents, lb: "first_name" + _lang, vl: "_id" }}
          />
          <Select
            label="Status"
            field="status"
            content={{ op: allStatus, lb: "request_status", vl: "_id" }}
          />

          <Select
            label="Anonymous"
            field="anonymous"
            content={{ op: annonymousData, lb: "label", vl: "value" }}
            condition={advancedSearch?.some((ele) => ele?.value == "anonymous")}
          />
          <Select
            label="Gender"
            field="gender"
            content={{ op: genderData, lb: "label", vl: "value" }}
            condition={advancedSearch?.some((ele) => ele?.value == "gender")}
          />
        </FilterBox>
        <ReportCounts data={data} />
        <Table
          css={1}
          _css={{ search: 2, pagination: 1, entries: 1 }}
          className={'reports_table'}
          moreFilters={
            <Select
              css={2}
              field="column_filter"
              placeholder={'Active Columns'}
              // Multi2
              // value={advancedFilter}
              onChange={advancedFilterChangeHandler}
              content={{ op: AdvancedFilterOptions.filter(af => !advancedFilter.find(ai => ai.value === af.value)), lb: "label", vl: "value" }}
            />
          }
          activeColumns={advancedFilter.map(af => af.label)}
          onActiveColumnsChange={(e) => setAdvancedFilter(advancedFilter.filter(s => s.label !== e))}
          titles={activeTitles()}
          content={reportData?.map((item) => {
            return {
              ticket_no: item.ticket_no,
              created_date: <DateViewer dateTime={item.createdAt} />,
              handled_by: item.handled_by ? (
                <Image
                  src={`${IMG_BASE_URL}profile/${item.handled_by?.profile_image}`}
                  label={item.handled_by?.["first_name" + _lang]}
                />
              ) : (
                "-"
              ),
              customer_name: item[`customer_name${_lang}`],
              customer_contact: item?.customer_contact
                ? (item?.customer_contact_code || "+971") +
                " " +
                item?.customer_contact
                : "-",
              customer_email: item?.customer_email,
              request_type: <StatusBox css={2} status={item?.request_type} />,
              incident_type: item.incident_type?.[`incident_type${_lang}`],
              department: item.department?.[`department_name${_lang}`],
              status: (
                <StatusBox css={1} status={item?.status?.request_status} />
              ),
              is_anonymous: item?.is_anonymous ? "Yes" : "No",
              precise_location: item?.precise_location,
              ip_addr: item?.ip_addr,
              device: item?.device ? Capitalize(item?.device) : "",
              device_id: item?.device_id,
              reported_lang: item?.reported_lang
                ? item?.reported_lang == "ar"
                  ? "Arabic"
                  : "English"
                : "",
              latitude: item?.latitude,
              longitude: item?.longitude,
              customer_nationality: item?.customer_nationality?.name,
              customer_gender: item?.customer_gender
                ? Capitalize(item?.customer_gender)
                : "",
            };
          })}
          pageDetails={pageDetails}
          filterHandler={filterHandler}
          filterData={filterData}
        />
      </Page.Body>
    </Page>
  );
}
