import React, { useEffect, useRef } from "react";

import Title from "../../_common/_components/Title/Title";
import Breadcrumb from "../../_common/_components/Breadcrumb/Breadcrumb";
import Page from "../../_common/_components/Page/Page";
import Table from "../../_common/_components/Table/Table";
import Select from "../../_common/_components/Select/Select";
import Button, { Buttons } from "../../_common/_components/Button/Button";
import Image from "../../_common/_components/Image/Image";
import Actions from "../../_common/_components/Actions/Actions";
import FilterBox from "../../_common/_components/FilterBox/FilterBox";
import useFilter from "../../_common/_Hooks/useFilter";
import useFetch from "../../_common/_Hooks/useFetch";
import List from "../../_common/_components/List/List";
import { useNavigate } from "react-router";
import { IMG_BASE_URL, SECRETKEY } from "../../config";
import { Modals } from "../../_common/_components/ModalsContainer/ModalsContainer";
import useTranslate from "../../_common/_Hooks/useTranslate";
import cryptoJs from "crypto-js";
import { v4 } from "uuid";
import useModal from "../../_common/_Hooks/useModal";
import { Confirm, ResetPassword } from "../../common/Modals";
import useForm from "../../_common/_Hooks/useForm";
import usePasswordProtect from "../../_common/_Hooks/usePasswordProtect";
import Input from "../../_common/_components/Input/Input";
import Div from "../../_common/_components/Div/Div";

export default function UpdatePassword() {
  const uuid = v4();
  const ResetPasswordModal = useModal(<Confirm />);

  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  usePasswordProtect({ passwordRef });
  usePasswordProtect({ passwordRef: confirmPasswordRef });
  const { formPlug, inputs, setInputs, validate, setErrors } = useForm();

  // *********************************************************************************************************************
  const { req: resetPassword } = useFetch({
    endpoint: "common/resetEmployeePassword",
    method: "post",
    type: "body",
    toast: true,
  });

  async function resetPasswordHandler(u) {
    let { cancel } = await ResetPasswordModal.show({
      action: "update password",
    });
    if (cancel) return;
    resetPassword(
      {
        employeeId: inputs.employee_id?.trim(),
        password:
          cryptoJs.AES.encrypt(inputs.password?.trim(), uuid).toString() +
          "_" +
          window.btoa(uuid + "_" + inputs.employee_id?.trim()),
      },
      (res) => {
        if (res.status) {
          cancelHandler();
        }
      }
    );
  }

  async function submitHandler() {
    if (!(await validate())) return;
    if (inputs?.password !== inputs?.confirm_pwd) {
      setErrors((s) => ({ ...s, confirm_pwd: "Passwords do not match" }));
      return;
    }
    resetPasswordHandler();
  }

  const cancelHandler = () => {
    setInputs();
    setErrors();
  };

  return (
    <Page>
      <Page.Header>
        <Title title="Update Password" />
      </Page.Header>

      <Page.Body css={1}>
        <Div css={1}>
          <div className="col-6">
            <Input
              css={1}
              label="Employee Id"
              field="employee_id"
              inputSocket={formPlug}
              required
              mb={15}
            />
            <Input
              css={1}
              label="New Password"
              ref={passwordRef}
              field="password"
              type={"password"}
              inputSocket={formPlug}
              validation={{ minChar: { length: 8 } }}
              required
              mb={15}
            />
            <Input
              css={1}
              label="Confirm Password"
              ref={confirmPasswordRef}
              field="confirm_pwd"
              type={"password"}
              inputSocket={formPlug}
              required
              mb={15}
            />
          </div>
          <Buttons end mt={20}>
            <Button content="Cancel" css={1} onClick={() => cancelHandler()} />
            <Button content="Submit" css={1.1} onClick={submitHandler} />
          </Buttons>
        </Div>
      </Page.Body>
    </Page>
  );
}
