export const filterDateSetter = (filter, key) => {
    if (filter?.[key]) {
        filter[key] = new Date(filter?.[key]).toISOString().split('T')[0]
    }
}

export const classNameMaker = (text = '') => {
    return text.split(' ').join('_').toLowerCase()
}

export const Capitalize = (text) => {
    return text ? text[0].toUpperCase() + text.slice(1) : "";
  };
  